<template lang="pug">
v-container()
  FwdHeadingLarge pricing settings
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      Tiers
    v-flex(xs6).pl-3
      DecorationCodes
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      DecorationCategories
    v-flex(xs6).pl-3
      
</template>

<script>
import Tiers from '@/modules/tiers/views/list'
import { list as DecorationCodes } from '@/modules/decoration-codes'
import { list as DecorationCategories } from '@/modules/decoration-categories'

export default {
  components: {
    Tiers,
    DecorationCodes,
    DecorationCategories
  }
}
</script>
